<template>

  <div class="flex-col page"
    style=" background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16633104515715228072.png')">
    <Wapheader />
    <div class="flex-col space-y-17">
      <div class="flex-col">
        <div class="flex-col group_2">
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16631458482976410394.png"
            class="image_32" />
          <div class="flex-col space-y-792 group_2">
            <div class="flex-col">
              <div style="opacity: 0;" class="flex-row justify-between section_2">
                <img
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/7aac57f92046566f1b38145db82c5d21.png"
                  class="image" />
                <img
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16626005966223060878.png"
                  class="image_1" />
                <img
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16626005966407546710.png"
                  class="image_2" />
              </div>
              <div class="flex-col group_3">
                <img
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16631458484694363410.png"
                  class="image_3" />
                <div class="flex-col items-start group_4">
                  <img
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16631458483358533471.png"
                    class="image_4" />
                  <div class="flex-col items-start image-wrapper">
                    <img
                      src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16631458485028708714.png"
                      class="image_5" />
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-row justify-center space-x-45 section_3">
              <div class="flex-col items-center text-wrapper"><span class="text" @click="navPath('/newwapyy')">预约试驾</span></div>
              <div class="flex-col items-center text-wrapper_1"><span class="text_1">购买商城</span></div>
            </div>
          </div>
        </div>
        <div class="flex-col section_4">
          <div class="flex-col space-y-81 section_5">
            <div>
              <span class="text_2">随智 幻动。</span>
              <span class="text_3">
                智享骑趣稳中得力，鲜行一步唯速当道。
                <br />
              </span>
              <span class="text_4">
                一步内，体验智慧系统互联科技；百里间，驰骋之路乐享驾乘感受。
                <br />
              </span>
              <span class="text_5">智驾领衔当代出行方式，用更速 角逐未来移动局势！</span>
            </div>
            <div class="flex-col items-start space-y-10">
              <span class="text_6">观看视频</span>

            </div>
          </div>
        </div>
        <div class="flex-col section_7">
          <div class="flex-col items-center space-y-29 section_8">
            <span class="text_7">极致的驾驭体验</span>
            <div class="group_7">
              <span class="text_8">
                强大的动力输出
                <br />
              </span>
              <span class="text_9">
                极致的轻量化设计
                <br />
              </span>
              <span class="text_10">完美的热管理系统</span>
            </div>
          </div>
        </div>
        <img
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16631458480479485112.png"
          class="image_6" />
      </div>
      <div class="grid">
        <div class="flex-col items-center space-y-25 grid-item">
          <div>
            <span class="text_11">3.7</span>
            <span class="text_13">s</span>
          </div>
          <span class="text_15">0-50km/h加速</span>
        </div>
        <div class="flex-col items-center space-y-25 grid-item">
          <div>
            <span class="text_11">92</span>
            <span class="text_13">km/h</span>
          </div>
          <span class="text_15">最高时速</span>
        </div>
        <div class="flex-col items-center space-y-25 grid-item">
          <div>
            <span class="text_11">155</span>
            <span class="text_13">km</span>
          </div>
          <span class="text_15">续航力</span>
        </div>
        <div class="flex-col items-center space-y-25 grid-item">
          <div>
            <span class="text_11">7.2</span>
            <span class="text_13">kw</span>
          </div>
          <span class="text_15">最大功率</span>
        </div>
      </div>
    </div>
    <div style="padding: 130px 40px 0 40px" class="flex-col items-start space-y-30 group_17">
      <span class="text_26">给够动力 就够给力</span>
      <span class="text_27">由智慧电控核心满擎驱动</span>
    </div>
    <div class="flex-col group_16">
      <div class="swiper swiper1">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="flex-col section_9">
              <div class="flex-col group_18">
                <span class="text_28">VCU核心动力平台</span>
                <span class="text_29">智能于心，更胜于形！</span>
                <div class="group_19">
                  <span class="text_30">
                    具备强大的算法能力，
                    <br />
                  </span>
                  <span class="text_31">在动态中实时平衡各项动力输出</span>
                </div>
              </div>
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16631458480347138090.png"
                class="image_7" />
              <div class="flex-row justify-center space-x-18 group_20">
                <div class="section_10">
                  <!---->
                </div>
                <div class="section_11">
                  <!---->
                </div>
                <div class="section_11">
                  <!---->
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="flex-col section_9">
              <div class="flex-col group_18">
                <span class="text_28">115V高电压平台</span>
                <span class="text_29">高电压串联式电力结构</span>
                <div class="group_19">
                  <span class="text_30">
                    全球街跑电摩领先性设计，采用三星21700-50ah高性
                    <br />
                    能锂电，115V高压电串联结构。
                  </span>
                  <div class="text_31">高效率，发热少，寿命长，支持更强动力输出和更高速度，整车效能提升。</div>
                </div>
              </div>
              <img src="../../assets/images/waps7/banner2.png" class="image_7 image_7New" />
              <div class="flex-row justify-center space-x-18 group_20">
                <div class="section_10" style="background-color:#7d7d7d;">
                  <!---->
                </div>
                <div class="section_11" style="background-color: #00b9ff">
                  <!---->
                </div>
                <div class="section_11">
                  <!---->
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="flex-col section_9">
              <div class="flex-col group_18">
                <span class="text_28">赛跑级空冷电机</span>
                <span class="text_29">11.2KG，同级电摩中重量最轻</span>
                <div class="group_19 group_19New">
                  <span class="text_30 text_30New">
                    扭力两倍于125CC燃油车
                    <br />
                  </span>
                  <!-- <span class="text_31">在动态中实时平衡各项动力输出</span> -->
                </div>
              </div>
              <div class="banner3-line">
                <div class="items-center">
                  <div class="banner3-line-item1"></div>
                  <div>S7</div>
                </div>
                <div class="items-center" style="margin-top:5px">
                  <div class="banner3-line-item1" style="background:#818181;width:35%"></div>
                  <div>125CC燃油车</div>
                </div>
              </div>
              <img src="../../assets/images/waps7/banner3.png" class="image_7 image_7New2" />
              <div class="flex-row justify-center space-x-18 group_20">
                <div class="section_10" style="background-color:#7d7d7d;">
                  <!---->
                </div>
                <div class="section_11">
                  <!---->
                </div>
                <div class="section_11" style="background-color: #00b9ff">
                  <!---->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="flex-col section_12">
        <div class="flex-col items-start space-y-29 group_21">
          <span class="text_32">轻装上阵 随心所驭</span>
          <span class="text_33">极致的轻量化设计助力赛道级操控</span>
        </div>
        <div style="width:100%">
          <van-swipe class="my-swipe" :autoplay="3000">
            <van-swipe-item>
              <div class="section_12New">
                <div class="flex-col space-y-83 section_13">
                  <span class="text_34">整体核心构建轻量化</span>
                  <div class="flex-col group_22">
                    <div class="flex-col items-center text-wrapper_2"><span class="text_35">整车重量</span></div>
                    <span class="text_36">119KG</span>
                  </div>
                </div>
                <div class="flex-row justify-center space-x-18 group_23">
                  <div class="section_10">
                    <!---->
                  </div>
                  <div class="section_11">
                    <!---->
                  </div>
                  <div class="section_11">
                    <!---->
                  </div>
                </div>
              </div>
            </van-swipe-item>
            <van-swipe-item>
              <div class="section_12New">
                <div class="flex-col space-y-83 section_13">
                  <span class="text_34">包含两颗</span>
                  <div class="flex-col group_22">
                    <div class="flex-col items-center text-wrapper_2"><span class="text_35">动力锂电池</span></div>
                    <span class="text_36">11KG</span>
                  </div>
                </div>
                <div class="flex-row justify-center space-x-18 group_23">
                  <div class="section_10" style="background:#7d7d7d">
                    <!---->
                  </div>
                  <div class="section_11" style="background:#00b9ff">
                    <!---->
                  </div>
                  <div class="section_11">
                    <!---->
                  </div>
                </div>
              </div>
            </van-swipe-item>
            <van-swipe-item>
              <div class="section_12New">
                <div class="flex-col space-y-83 section_13">
                  <span class="text_34">轻量化助力赛道级操控</span>
                  <div class="flex-col group_22">
                    <div class="flex-col items-center text-wrapper_2"><span
                        class="text_35">对比同级燃油摩托车<br>车身重量减少37%</span></div>
                    <span class="text_36">37%</span>
                  </div>
                </div>
                <div class="flex-row justify-center space-x-18 group_23">
                  <div class="section_10" style="background:#7d7d7d">
                    <!---->
                  </div>
                  <div class="section_11">
                    <!---->
                  </div>
                  <div class="section_11" style="background:#00b9ff">
                    <!---->
                  </div>
                </div>
              </div>
            </van-swipe-item>
          </van-swipe>
        </div>


      </div>

      <div class="flex-col section_14">
        <div class="flex-col items-center space-y-29">
          <div style="display: flex;align-items: center;flex-direction: column;">
            <span class="text_37">完美的热管理系统</span>
            <span class="text_38">赛道级空气冷却设计</span>
          </div>

        </div>
        <div style="width:100%">
          <van-swipe class="my-swipe" :autoplay="3000">
            <van-swipe-item>
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16631458475608240267.png"
                class="image_8" />
              <div class="flex-col group_25">
                <span class="text_39">赛道级空气导流设计</span>
                <div class="group_26">
                  <span class="text_40">
                    将轻量化设计完美融入
                    <br />
                  </span>
                  <span class="text_41">热管理系统的开发当中</span>
                </div>
              </div>
              <div class="flex-row justify-center space-x-18 group_27">
                <div class="section_10">
                  <!---->
                </div>
                <div class="section_11">
                  <!---->
                </div>
              </div>
            </van-swipe-item>
            <van-swipe-item>
              <img
                src="../../assets/images/waps7/sdj-banner2.png"
                class="image_8" />
              <div class="flex-col group_25">
                <span class="text_39">螺纹式散热鳍片</span>
                <div class="group_26">
                  <span class="text_40">
                    加之导风流道设计
                    <br />
                  </span>
                  <span class="text_41">可以使空气更大量的通过马达表面</span>
                </div>
              </div>
              <div class="flex-row justify-center space-x-18 group_27">
                <div class="section_10" style="background:#7d7d7d">
                  <!---->
                </div>
                <div class="section_11" style="background:#00b9ff">
                  <!---->
                </div>
              </div>

            </van-swipe-item>
          </van-swipe>

        </div>

      </div>
    </div>
    <span class="text_42">极致操控 赢在细节</span>
    <div class="flex-col space-y-34 group_28">
      <div class="flex-col">
        <div class="flex-col items-center image-wrapper_1">
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16631458456578059290.png"
            class="image_9" />
        </div>
        <div class="flex-col space-y-27 section_15">
          <span class="text_43">高抓力轮胎</span>
          <div class="group_30">
            <span class="text_44">
              为极限驾驶的骑士打造的高性能轮胎，
              <br />
            </span>
            <span class="text_45">
              采用类全热熔的高抓地胶料和胎冠中沟的设计，
              <br />
            </span>
            <span class="text_46">让你稳操胜券。</span>
          </div>
        </div>
        <div class="flex-col items-center image-wrapper_2">
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16631458456578059290.png"
            class="image_9" />
        </div>
        <div class="flex-col space-y-26 section_16">
          <span class="text_47">BOSCH ABS 10.1防锁死煞车系统</span>
          <div class="group_31">
            <span class="text_48">
              搭配ECU与轮速感知器，
              <br />
            </span>
            <span class="text_49">
              强震撼级制动规格三活塞卡钳，
              <br />
            </span>
            <span class="text_50">即使电光一霎间的止步，也可绰有余暇。</span>
          </div>
        </div>
      </div>
      <div class="flex-col">
        <div class="flex-col items-center image-wrapper_3">
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16631458456578059290.png"
            class="image_9" />
        </div>
        <div class="flex-col items-center space-y-26 section_17">
          <span class="text_51">首创自牵引防滑系统</span>
          <div class="group_33">
            <span class="text_52">
              前后轮脉脉相通的自牵引力，
              <br />
            </span>
            <span class="text_53">
              给你制动时分的安全感，
              <br />
            </span>
            <span class="text_54">施金石之计，行安全之路。</span>
          </div>
        </div>
      </div>
      <div class="flex-col">
        <div class="flex-col items-center image-wrapper_4">
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16631458456578059290.png"
            class="image_9" />
        </div>
        <div class="flex-col space-y-26 section_18">
          <span class="text_55">全车LED汽车级灯具</span>
          <span class="text_56">
            Hazard智慧警示灯系统，以方向灯及紧急刹车警示灯为优先的智慧判断控制警示灯系统，EBS紧急刹车警示灯，紧急制动警示灯自动亮起，并快速闪烁，减少追撞几率。
          </span>
        </div>
      </div>
    </div>
    <constart car="s7"></constart>
    <Wapfooter />
  </div>
</template>

<script>
import constart from "../../components/Newconstart/index.vue"
import { Swipe, SwipeItem } from 'vant';
export default {
  components: {
    constart,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem
  },
  data() {
    return {

    };
  },
  mounted() {
    this.$nextTick(() => {
      new Swiper('.swiper1', {
        loop: true,
        autoplay: true,//可选选项，自动滑动
      })
    })
  },

  methods: {},
};
</script>

<style scoped lang="less">
.section_12New {
  width: 100%;
  padding: 0 135px;
}

/deep/ .my-swipe {
  margin: 0 auto;

}

/deep/ .van-swipe__track {
  display: flex !important;
}

.banner3-line {
  padding-left: 51px;
  margin-top: 55px;
  font-family: 'Albb';
  color: #fff;
}

.banner3-line-item1 {
  width: 70%;
  border-radius: 10px;
  height: 14px;
  background-image: linear-gradient(to right, #0064FF, #00FBF2);
  margin-right: 20px;
}

.page {
  /* background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16633104515715228072.png'); */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.space-y-17>*:not(:first-child) {
  margin-top: 17px;
}

.group_2 {
  position: relative;
}

.image_32 {
  width: 750px;
  height: 1334.00025px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}

.space-y-792>*:not(:first-child) {
  margin-top: 822px;
}

.section_2 {
  padding: 30px 30px 29px;
  background-color: #00000126;
}

.image {
  margin-bottom: 3px;
  width: 40px;
  height: 36px;
}

.image_1 {
  width: 260px;
  height: 41px;
}

.image_2 {
  width: 40px;
  height: 40px;
}

.group_3 {
  padding-left: 81px;
  padding-top: 40px;
}

.image_3 {
  align-self: center;
  width: 538px;
  height: 139px;
}

.group_4 {
  padding: 18px 0 14px;
  position: relative;
}

.image_4 {
  width: 596px;
  height: 70px;
}

.image-wrapper {
  padding: 7px 0 50px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16631458484089001625.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 566px;
  position: absolute;
  right: 0;
  top: 0;
}

.image_5 {
  width: 363px;
  height: 45px;
}

.space-x-45>*:not(:first-child) {
  margin-left: 45px;
}

.section_3 {
  padding: 39px 140px 39px;
  background-color: #0e0e0e;
}

.text-wrapper {
  padding: 18px 0 16px;
  border-radius: 30px;
  width: 212px;
  height: 61px;
  border: solid 2px #007bff;
}

.text {
  color: #ffffff;
  font-size: 24px;
  font-family: 'Albbr';
  line-height: 23px;
}

.text-wrapper_1 {
  padding: 20px 0 18px;
  background-color: #007bff;
  border-radius: 30px;
  width: 212px;
  height: 61px;
}

.text_1 {
  color: #ffffff;
  font-size: 24px;
  font-family: 'Albbr';
  line-height: 23px;
}

.section_4 {
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16631458482269689431.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.space-y-81>*:not(:first-child) {
  margin-top: 81px;
}

.section_5 {
  padding: 122px 70px 197px 78px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16631458482881337186.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.text_2 {
  color: #00b6ff;
  font-size: 36px;
  font-family: 'Albbm';
  line-height: 60px;
}

.text_3 {
  color: #ffffff;
  font-size: 36px;
  font-family: 'Albbm';
  line-height: 60px;
}

.text_4 {
  color: #ffffff;
  font-size: 36px;
  font-family: 'Albbm';
  line-height: 60px;
}

.text_5 {
  color: #ffffff;
  font-size: 36px;
  font-family: 'Albbm';
  line-height: 60px;
}

.space-y-10>*:not(:first-child) {
  margin-top: 10px;
}

.text_6 {
  color: #007aff;
  font-size: 30px;
  padding-bottom: 10px;
  border-bottom: 2px solid #007bff;
  line-height: 28px;
}

.section_6 {
  background-color: #007bff;
  width: 115px;
  height: 2px;
}

.section_7 {
  padding-top: 176px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16631458480632131792.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.space-y-29>*:not(:first-child) {
  margin-top: 29px;
}

.section_8 {
  padding: 319px 0 46px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16631458481795202405.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.text_7 {
  color: #ffffff;
  font-size: 48px;
  font-family: 'Albb';
  line-height: 46px;
}

.group_7 {
  text-align: center;
}

.text_8 {
  color: #ffffff;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 48px;
}

.text_9 {
  color: #ffffff;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 48px;
}

.text_10 {
  color: #ffffff;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 48px;
}

.image_6 {
  margin: 11px 30px 0;
  width: 690px;
  height: 680.00025px;
}

.grid {
  align-self: center;
  width: 544px;
  height: 336px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.space-y-25>*:not(:first-child) {
  margin-top: 25px;
}

.grid-item {
  padding: 38px 0;
  width: 272px;
}

.text_11 {
  color: #ffffff;
  font-size: 60px;

  font-weight: 700;
  line-height: 44px;
}

.text_13 {
  color: #ffffff;
  font-size: 36px;

  line-height: 28px;
}

.text_15 {
  color: #ffffff;
  font-size: 24px;

  line-height: 23px;
}

.group_16 {
  margin-top: 64.5px;
}

.section_9 {
  padding: 0 30px 86px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16631458479552055912.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.space-y-30>*:not(:first-child) {
  margin-top: 30px;
}

.group_17 {
  padding: 0 50px;
}

.text_26 {
  color: #ffffff;
  font-size: 48px;

  font-weight: 700;
  line-height: 45px;
}

.text_27 {
  color: #ffffff;
  font-size: 28px;

  line-height: 27px;
}

.group_18 {
  margin-left: 51px;
  margin-top: 113px;
  align-self: flex-start;
  /* width: 408px; */
}

.text_28 {
  align-self: flex-start;
  color: #00b9ff;
  font-size: 36px;
  font-family: 'Albb';
  line-height: 35px;
}

.text_29 {
  margin-top: 18px;
  align-self: flex-start;
  color: #ffffff;
  font-size: 28px;
  font-family: 'Albb';
  line-height: 27px;
  letter-spacing: 1.12px;
}

.group_19 {
  margin-top: 40px;
}

.group_19New {
  margin-top: 60px !important;
}

.text_30 {
  color: #ffffff;
  font-size: 24px;
  font-family: 'Albbr';
  line-height: 40px;

}

.text_30New {
  font-size: 28px !important;
}

.text_31 {
  color: #ffffff;
  font-size: 24px;
  font-family: 'Albbr';
  line-height: 40px;

}

.image_7 {
  margin-top: 164px;
  width: 690px;
  height: 411.99975000000006px;
}

.image_7New {
  margin-top: 80px !important;
}

.image_7New2 {
  margin-top: 40px !important;
}

.space-x-18>*:not(:first-child) {
  margin-left: 18px;
}

.group_20 {
  margin-top: 121px;
}

.section_10 {
  background-color: #00b9ff;
  width: 98px;
  height: 6px;
}

.section_11 {
  background-color: #7d7d7d;
  width: 98px;
  height: 6px;
}

.section_12 {
  padding: 131px 0 461px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16631458476032273524.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.group_21 {
  padding: 0 13px;
}

.text_32 {

  color: #ffffff;
  font-size: 48px;
  font-family: 'Albb';
  line-height: 45px;
  width: 100%;
  text-align: center;
}

.text_33 {
  color: #ffffff;
  font-size: 28px;
  width: 100%;
  text-align: center;
  line-height: 27px;
}

.space-y-83>*:not(:first-child) {
  margin-top: 83px;
}

.section_13 {
  margin-top: 84px;
  padding: 108px 58px 99px 62px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16633104515931057647.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.text_34 {
  width: 100%;
  text-align: center;
  align-self: flex-start;
  color: #ffffff;
  font-size: 35px;
  font-family: 'Albb';
  line-height: 33px;
}

.group_22 {
  padding-top: 74px;
  position: relative;
}

.text-wrapper_2 {
  margin-right: 4px;
  padding: 42px 0 13px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16631458478771667710.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.text_35 {
  color: #ffffff;
  font-size: 31px;
  font-family: 'Albbr';
  // line-height: 29px;
  text-align: center;
}

.text_36 {
  color: #02ffff;
  font-size: 115px;
  font-family: 'Albbh';
  line-height: 84px;
  letter-spacing: -5.76px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  text-align: center;
}

.group_23 {
  margin-top: 70px;
  padding: 0 76px;
}

.section_14 {
  padding: 122px 0 201px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16631458473939423189.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.text_37 {
  color: #ffffff;
  font-size: 48px;

  font-weight: 700;
  line-height: 45px;
}

.text_38 {
  color: #ffffff;
  font-size: 28px;
  margin-top: 30px;
  line-height: 27px;
}

.image_8 {
  width: 750px;
  height: 501.99975000000006px;
  margin-top: 60px;
}

.group_25 {
  margin-top: 64px;
  align-self: center;
  text-align: center;
}

.text_39 {
  color: #00b9ff;
  font-size: 36px;
  font-family: 'Albb';
  line-height: 35px;
}

.group_26 {
  margin: 28px 0;
  line-height: 36px;
  text-align: center;
}

.text_40 {
  color: #ffffff;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 36px;
  letter-spacing: 1.12px;
}

.text_41 {
  color: #ffffff;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 36px;
  letter-spacing: 1.12px;
}

.group_27 {
  margin-top: 175px;
}

.text_42 {
  margin-top: 136px;
  align-self: center;
  color: #ffffff;
  font-size: 48px;
  font-family: 'Albb';
  line-height: 45px;
}

.space-y-34>*:not(:first-child) {
  margin-top: 34px;
}

.group_28 {
  // margin-top: 78px;
  // padding: 0 30px;
  // margin-bottom: 136px;
}

.image-wrapper_1 {
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16631458456570859250.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.image_9 {
  width: 690px;
  height: 440.00024999999994px;
}

.space-y-27>*:not(:first-child) {
  margin-top: 27px;
}

.section_15 {
  padding: 46px 72px 53px;
  background-color: #1b1b1b;
}

.text_43 {
  align-self: center;
  color: #00b9ff;
  font-size: 28px;
  font-family: 'Albb';
}

.group_30 {
  text-align: center;
  line-height: 36px;
}

.text_44 {
  color: #ffffff;
  font-size: 24px;
  line-height: 36px;
}

.text_45 {
  color: #ffffff;
  font-size: 24px;
  line-height: 36px;
}

.text_46 {
  color: #ffffff;
  font-size: 24px;
  line-height: 36px;
}

.image-wrapper_2 {
  margin-top: 34px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16631458456579035745.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.space-y-26>*:not(:first-child) {
  margin-top: 26px;
}

.section_16 {
  padding: 46px 109px 53px 112px;
  background-color: #1b1b1b;
}

.text_47 {
  margin-left: 10px;
  color: #00b9ff;
  font-size: 28px;
  font-family: 'Albb';
}

.group_31 {
  line-height: 36px;
  text-align: center;
}

.text_48 {
  color: #ffffff;
  font-size: 24px;
 
  line-height: 36px;
}

.text_49 {
  color: #ffffff;
  font-size: 24px;

  line-height: 36px;
}

.text_50 {
  color: #ffffff;
  font-size: 24px;
  line-height: 36px;
}

.image-wrapper_3 {
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16631458471717262643.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.section_17 {
  padding: 46px 0 53px;
  background-color: #1b1b1b;
}

.text_51 {
  color: #00b6ff;
  font-size: 28px;
  font-family: 'Albb';
}

.group_33 {
  line-height: 36px;
  text-align: center;
}

.text_52 {
  color: #ffffff;
  font-size: 24px;
  line-height: 36px;
}

.text_53 {
  color: #ffffff;
  font-size: 24px;
  line-height: 36px;
}

.text_54 {
  color: #ffffff;
  font-size: 24px;
  line-height: 36px;
}

.image-wrapper_4 {
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16631458471855866448.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.section_18 {
  padding: 46px 45px 42px 50px;
  background-color: #1b1b1b;
}

.text_55 {
  align-self: center;
  color: #00b6ff;
  font-size: 28px;
  font-family: 'Albb';
}

.text_56 {
  color: #ffffff;
  font-size: 24px;
  text-align: center;
  line-height: 36px;
  width: 101%;
}

.group_35 {
  margin-top: 190px;

}

.group_36 {
  height: 4070px;
}

.section_19 {
  padding: 167px 66px 176px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16631458456544913408.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.text_57 {
  align-self: center;
  color: #fffefe;
  font-size: 42px;
  font-family: OPPOSans;
  line-height: 41px;
}

.group_37 {
  margin-top: 87px;
  height: 599px;
  position: relative;
}

.text_58 {
  color: #adbac5;
  font-size: 28px;
  font-family: OPPOSans;
  line-height: 28px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.image_14 {
  width: 618px;
  height: 223px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 14px;
}

.image_15 {
  width: 454px;
  height: 513px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.group_38 {
  margin-top: 43px;
}

.image_16 {
  width: 51px;
  height: 51px;
}

.image_31 {
  margin-left: 236px;
}

.image_18 {
  margin-right: 236px;
}

.button {
  margin-top: 74px;
  padding: 26px 0;
  align-self: center;
  border-radius: 46px;
  width: 332px;
  border: solid 4px #ffffff;
}

.text_59 {
  color: #ffffff;
  font-size: 32px;
  font-family: OPPOSans;
  line-height: 32px;
}

.image_19 {
  flex-shrink: 0;
  align-self: center;
  width: 750px;
  height: 777.9997500000001px;
}

.image_20 {
  margin-top: -620px;
  flex-shrink: 0;
  align-self: center;
  width: 590px;
  height: 2578px;
}
</style>